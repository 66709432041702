import React from 'react'
import CreateAccount from './CreateAccount'
const Registration = () => {
  return (
    <div>
    <CreateAccount />
    </div>
  )
}

export default Registration
