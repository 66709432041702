import React from 'react'
import Dashboard from './Dashboard'

const DashboardMain = () => {
  return (
    <div>
   <Dashboard /> 
   
  {/* <FinishedFightUserBoard />   */}
   {/* <FightDetails /> */}
   {/* <PurchaseTokensIntimation />  */}
  {/*  <MakePredictions />  */}
  {/*  <FightLeaderboard />  */}

    </div>
  )
}

export default DashboardMain
